.dashboard-layout {
    width: 100%;
    display: flex;
    
}

.side-nav {
    max-width: 350px;
    width: 100%;
    height: 100vh;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    background: #FEFEFE;
    border-right: 1.5px solid rgba(0, 0, 0, 0.1);
}

.sub-layout {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

.dashboard-logo {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.dashboard-logo img{
    width: 180px;
    height: auto;
}

.navigation 
{
    padding: 0 46px 0 38px;
    flex-grow: 1;
    margin-top: 43px;
    overflow-y: scroll;
}

.nav-item {
    position: relative;
    display: flex;
    gap: 20px;
    color: var(--primary-black);
    position: relative;
    align-items: center;
}

.nav-item, .nested-nav-item {
    text-decoration-line: none;
    margin-bottom: 31px;
}

.nav-item:not(.active), .parent-nav:not(.active) {
    opacity: .5;
}

.nav-item img, .nested-nav-item img {
    height: 18px;
    width: auto;
}

.nested-nav-item {
    position: relative;
}

.nested-nav-item .downarrow {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.parent-nav.active .downarrow {
    transform: translateY(-50%) rotateZ(180deg);
}

.nav-item.active::before, .parent-nav.active::before {
    content: '';
    display: block;
    width: 5px;
    height: 50px;
    background-color: var(--primary-black);
    position: absolute;
    top: 50%;
    left: -38px;
    transform: translateY(-50%);
}

.parent-nav
{
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 20px;
    color: var(--primary-black);
}

.child-nav .sub-nav-item {
    text-decoration: none;
    display: block;
    padding: 0 38px;
    margin-top: 34px;
    color: var(--primary-black);
}

.child-nav .sub-nav-item:not(.active) {
    opacity: .5;
}

.user-profile-section {
    flex-shrink: 0;
    padding-bottom: 40px;
}

.profile-layout {
    display: flex;
    align-items: center;
    padding: 15px 46px 15px 38px;
    margin-top: 32px;
    cursor: pointer;
    opacity: 0.5;
    position: relative;
}

.profile-layout:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.07);
}

.profile-popup 
{
    position: absolute;
    right: 16px;
    top: 0;
    padding: 10px;
    transform: translateY(-100%);
    display: none;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.202);
}

.profile-popup img 
{
    width: 18px;
    height: 18px;
}

.profile-popup>div {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 18px;
}

.profile-popup>div:hover {
    background-color: rgba(0, 0, 0, 0.07);
}

.profile-layout:hover .profile-popup
{
    display: block;
}

.profile-image 
{
    width: 47px;
    height: 47px;
}

.user-profile-divider {
    margin: 0 auto;
    width: 242px;
    height: 2px;
    background-color: black;
    opacity: .05;
}

.user-detail
{
    flex-grow: 1;
    padding-left: 18px;
    padding-right: 10px;
}

.user-detail span {
    display: block;
}

.logout-image 
{
    width: 20px;
    height: 20px;
}

.user-detail .name {
    font-size: 18px;
    margin-bottom: 6px;
}

.user-detail .role {
    font-size: 14px;
}

